import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Constants } from "../constants";

export class NetworkManager {
  private api; // Axios instance
  
  private refreshApi; // Külön axios instance a refresh token kérésekhez

  constructor() {
    const baseURL = Constants.API_URL;
    this.api = axios.create({
      baseURL,
    });

    // Ez az axios instance NEM tartalmazza az interceptorokat
    this.refreshApi = axios.create({
      baseURL,
    });

    this.api.interceptors.request.use(
      async (config) => {
        let accessToken = localStorage.getItem('accessToken');

        // Token lejárati idő kinyerése jwt-decode-dal
        const tokenExpiry = accessToken
          ? jwtDecode<{ exp: number }>(accessToken).exp * 1000
          : null;

        // Ha az accessToken lejárt, frissítjük
        if (tokenExpiry && Date.now() >= tokenExpiry) {
          accessToken = await this.refreshAccessToken();
        }
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // API kérés függvények
  async post(url: string, data: any) {
    return this.api.post(url, data);
  }

  async get(url: string) {
    return this.api.get(url);
  }

  // Token frissítési logika
  private async refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      console.error('Nincs refresh token.');
      throw new Error('Nincs refresh token');
    }

    try {
      // Külön axios instance használata, amelynél NINCS interceptor
      const response = await this.refreshApi.post('/api/refresh/', { refresh: refreshToken });
      const { access } = response.data;
      if (access) {
        localStorage.setItem('isSuperuser', jwtDecode<{ is_superuser: string }>(access).is_superuser);
        localStorage.setItem('accessToken', access);
        return access;
      } else {
        console.error('Nem sikerült frissíteni az access tokent.');
        throw new Error('Nem sikerült frissíteni az access tokent');
      }
    } catch (error) {
      console.log(error);
      console.error('Refresh token hiba:', error);
      throw error;
    }
  }
}