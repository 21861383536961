import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Autocomplete,
  useTheme,
} from "@mui/material";
import { Remove } from "@mui/icons-material";
import { NetworkManager } from "../services/NetworkManager";
import { ServiceSheet, Worksheet } from "../models/WorkSheetModel";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { Scanner } from '@yudiel/react-qr-scanner';

interface Location {
  id: number;
  name: string;
}

const ServiceSheetForm: React.FC = () => {
  const [serviceSheets, setServiceSheets] = useState<Worksheet[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [newLocation, setNewLocations] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const networkManager = useMemo(() => new NetworkManager(), []);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [isOpened, setIsOpened] = useState(false);
  const theme = useTheme();
  const [formData, setFormData] = useState<ServiceSheet>({
    payment_method: "card",
    expenses: [],
    incomes: [],
    location: ""
  });
  

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
  };

  const fetchLocations = useCallback(async () => {
    try {
      const response = await networkManager.get('/api/locations/');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, [networkManager]);

  const getWorksheets = useCallback(async () => {
    setIsLoading(true);
    
    const date = selectedDate?.format('YYYY-MM-DD') || dayjs();
    const response = await networkManager.get('/api/worksheets/?date=' + date);
    const newServiceSheets = response.data.worksheets;

    // Az új elemeket hozzáadjuk a meglévőkhöz
    setServiceSheets(newServiceSheets);
    setIsLoading(false);
  }, [networkManager, selectedDate]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: "expense" | "income"
  ) => {
    const { name, value } = e.target;
    if (type === "expense") {
      const expenses = [...formData.expenses];
      expenses[index] = { ...expenses[index], [name]: value };
      setFormData({ ...formData, expenses });
    } else {
      const incomes = [...formData.incomes];
      incomes[index] = { ...incomes[index], [name]: value };
      setFormData({ ...formData, incomes });
    }
  };

  useEffect(() => {
    setShowForm(false);
    getWorksheets();
    fetchLocations();
  },[selectedDate, fetchLocations, getWorksheets]);

  

  const addExpense = () => {
    setFormData({
      ...formData,
      expenses: [...formData.expenses, { description: "", price: "0", part: null, order_related: false }],
    });
  };
  const openQRScanner = () => {
    setIsOpened(true);
  }

  const handleScan = (result: string | null) => {
    if (result) {
      addPart(result);
      setIsOpened(false);
    }
  };
  const addPart = async (partId: string) => {
    const parsedPartId = JSON.parse(partId);
    await networkManager.get(`/api/parts/` + parsedPartId).then((response) => {
      const part = response.data;
      setFormData({
        ...formData,
        expenses: [...formData.expenses, { description: part.phone_type_name + " - " + part.part_type_name, price: part.price, part: part.part_id, order_related: true }],
      });
    }).catch((error) => {
    })
  
  }
  const removeExpense = (index: number) => {
    const expenses = formData.expenses.filter((_, i) => i !== index);
    setFormData({ ...formData, expenses });
  };

  const addIncome = () => {
    setFormData({
      ...formData,
      incomes: [...formData.incomes, { description: "", amount: "0", card: ""}],
    });
  };

  const removeIncome = (index: number) => {
    const incomes = formData.incomes.filter((_, i) => i !== index);
    setFormData({ ...formData, incomes });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await networkManager.post('/api/worksheets/', formData).then(() => {
      getWorksheets();
      setFormData({
        payment_method: "card",
        expenses: [],
        incomes: [],
        location: newLocation
      });
    } ).catch((error) => {
      console.error('Worksheet creation error:', error);
    });

    setShowForm(false);
  };
  return (
    isLoading ? (
      <div>Töltés...</div>
    ) : (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Válassz dátumot"
        value={selectedDate}
        onChange={handleDateChange}
        slots={{
          textField: textFieldProps => <TextField {...textFieldProps} />
        }}
      />
      </LocalizationProvider>
      <br></br>
      <br></br>
      {selectedDate?.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && (<Button variant="contained" color="primary" onClick={() => setShowForm(true)}>
      Új pénzmozgás felvitel
      </Button>)}
      

      {showForm && (
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h5" gutterBottom>
               Új pénzmozgás felvitel
              </Typography>
              <form onSubmit={handleSubmit}>
              <Autocomplete
  options={locations.map((location) => location.name)}
  value={newLocation}
  onChange={(event, newValue) => {
    setFormData({ ...formData, location: newValue ?? '' });
    setNewLocations(newValue ?? '');
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Helyszín"
      fullWidth
      required
    />
  )}
  freeSolo={false} // Nem lehet egyedi értéket beírni
      
    />
                <Typography variant="body1" style={{ marginBottom: "8px" }}>Készpénz / Bankkártya</Typography>
                <Select
                  fullWidth
                  value={formData.payment_method}
                  onChange={(e) => setFormData({ ...formData, payment_method: e.target.value as string })}
                >
                  <MenuItem value="card">Bankkártya</MenuItem>
                  <MenuItem value="cash">Készpénz</MenuItem>
                </Select>

                <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "8px" }}>Kiadások</Typography>
                {formData.expenses.map((expense, index) => (
                  <Grid container spacing={2} key={index} style={{ marginBottom: "8px" }}>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        name="description"
                        fullWidth
                        value={expense.description}
                        onChange={(e) => handleInputChange(e, index, "expense")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Ár"
                        name="price"
                        type="number"
                        fullWidth
                        value={expense.price}
                        onChange={(e) => handleInputChange(e, index, "expense")}
                      />
                    </Grid>
                    <IconButton onClick={() => removeExpense(index)}>
                      <Remove />
                    </IconButton>
                  </Grid>
                ))}
                <Button variant="outlined" onClick={addExpense}>
                  Kiadás hozzáadása
                </Button>
                <Button variant="outlined" onClick={openQRScanner} style={{ marginLeft: "8px" }}>
                  Alkatrész hozzáadása
                </Button>
                {isOpened && (
                <Scanner
        onScan={(result) => handleScan(result[0].rawValue)}
        onError={(error) => console.error("QR Code error:", error)}
      /> )}
                <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "8px" }}>Bevételek</Typography>
                {formData.incomes.map((income, index) => (
                  <Grid container spacing={2} key={index} style={{ marginBottom: "8px" }}>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        name="description"
                        fullWidth
                        value={income.description}
                        onChange={(e) => handleInputChange(e, index, "income")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Ár"
                        name="amount"
                        fullWidth
                        type="number"
                        value={income.amount}
                        onChange={(e) => handleInputChange(e, index, "income")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                    <Select
                      value={formData.incomes[index]?.card || ""}
                      displayEmpty // Megjeleníti az üres értéket is
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const incomes = [...formData.incomes];
                        incomes[index] = { ...incomes[index], card: value };
                        setFormData({ ...formData, incomes });
                      }}
                    >
                      <MenuItem value="">Kártya nélkül</MenuItem> {/* Üres string mint alapértelmezett érték */}
                      <MenuItem value="garancia100">Garancia100</MenuItem>
                      <MenuItem value="garancia50">Garancia50</MenuItem>
                      <MenuItem value="silver">Ezüst</MenuItem>
                      <MenuItem value="gold">Arany</MenuItem>
                    </Select>
                    </Grid>
                    <IconButton onClick={() => removeIncome(index)}>
                      <Remove />
                    </IconButton>
                    
                  </Grid>
                ))}
                <Button variant="outlined" onClick={addIncome}>
                  Bevétel hozzáadása
                </Button>
                <br></br>
                <Button variant="contained" color="primary" type="submit" style={{ marginTop: "15px" }}>
                  Felvitel
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      )}

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table key={"table"}>
          <TableHead key={"TablehxRow"}>
            <TableRow key={"Tablehead"}>
              <TableCell>ID</TableCell>
              <TableCell>Felhasználó</TableCell>
              <TableCell>Dátum</TableCell>
              <TableCell>Készpénz / Bankkártya</TableCell>
              <TableCell>Kiadások</TableCell>
              <TableCell>Bevételek</TableCell>
              <TableCell>Helyszín</TableCell>
            </TableRow>
          </TableHead>
          <TableBody> {}
            {serviceSheets.map((sheet) => (
              <TableRow key={sheet.id}>
                <TableCell>{sheet.id}</TableCell>
                <TableCell>{sheet.user}</TableCell>
                <TableCell>{sheet.id}</TableCell>
                <TableCell>{sheet.payment_method}</TableCell>
                <TableCell>
                {sheet.expenses && sheet.expenses.length > 0 && (
  sheet.expenses.map((expense, i) => (
    <div key={i}>
      {expense.description} - {parseInt(expense.price).toLocaleString() + " Ft"}
    </div>
  ))
)}
                </TableCell>
                <TableCell>
                  {sheet.incomes && sheet.incomes.map((income, i) => (
                    <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px 12px",
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderRadius: "8px",
                      margin: "8px 0",
                      backgroundColor: theme.palette.background.paper,
                      transition: "all 0.3s",
                      "&:hover": {
                        boxShadow: `0px 4px 12px ${theme.palette.grey[300]}`,
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    {/* Bal oldali, eredeti ár */}
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="textPrimary">
                          {income.description} - {parseInt(income.amount).toLocaleString()} Ft
                        </Typography>
                      </Grid>
          
                      {/* Középső, kártya információ */}
                      <Grid item xs={3}>
                        <Typography variant="body2" color="textSecondary">
                          {income.card === "" ? "Kártya nélkül" : income.card}
                        </Typography>
                      </Grid>
          
                      {/* Jobb oldali, új ár */}
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" color="primary" fontWeight="600">
                          {income.new_price ? parseInt(income.new_price).toLocaleString() + " Ft" : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  ))}
                </TableCell>
                <TableCell>
                    <div key={sheet.location}>
                      {sheet.location}
                    </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ));
};

export default ServiceSheetForm;