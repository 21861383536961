import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Box,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Autocomplete,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { BatteryChargingFull, PhoneIphone, ScreenRotation, Build, Power, CameraAlt, PrintTwoTone } from '@mui/icons-material';
// import QRCode from 'qrcode.react';
import { NetworkManager } from '../services/NetworkManager';
import { UserManager } from '../services/UserManager';

interface Part {
  part_id: number;
  part_type_id: number;
  part_type_name: string;
  price: string;
  is_sold: boolean;
  sold_date: string | null;
  purchase_date: string;
  from_name: string;
  from_id: number;
  color: string;
  phone_type_id: number;
  phone_type_name: string;
}

interface PhoneType {
  id: number;
  name: string;
}

interface PartType {
  id: number;
  name: string;
}

interface Supplier {
  id: number;
  name: string;
}

const PartsList = () => {
  const [parts, setParts] = useState<Part[]>([]);
  const [partTypes, setPartTypes] = useState<PartType[]>([]);
  const [phoneTypes, setPhoneTypes] = useState<PhoneType[]>([]);
  const [selectedPartTypes, setSelectedPartTypes] = useState<number[]>([]);
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState<number[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<number[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPartType, setNewPartType] = useState<number | string>('');
  const [newPhoneType, setNewPhoneType] = useState<number | string>('');
  const [newPrice, setNewPrice] = useState<string>('');
  const [newColor, setNewColor] = useState<string>('');
  const [supplier, setSupplier] = useState<string>('');
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const networkManager = new NetworkManager();
  const userManager = UserManager.getInstance();

  useEffect(() => {
    fetchSuppliers();
    fetchParts();
    fetchPhoneAndPartTypes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchSuppliers() {
      try {
        const response = await networkManager.get('/api/suppliers/');
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    }

    async function fetchParts() {
      try {
        const response = await networkManager.get('/api/parts/');
        setParts(response.data);
      } catch (error) {
        console.error('Error fetching parts:', error);
      }
    }

    async function fetchPhoneAndPartTypes() {
      try {
        const phoneTypesResponse = await networkManager.get('/api/phonetypes/');
        setPhoneTypes(phoneTypesResponse.data);
        
        const partTypesResponse = await networkManager.get('/api/parttypes/');
        setPartTypes(partTypesResponse.data);
      } catch (error) {
        console.error('Error fetching phone and part types:', error);
      }
    }
  // Handle new part addition (with QR code generation)
  const handleAddPart = async () => {
    if (!newPartType || !newPhoneType || !newPrice || !supplier) return;
  
    try {
      // POST kérés az API-hoz
      const response = await networkManager.post('/api/parts/', {
        part_type_id: newPartType,
        phone_type_id: newPhoneType,
        price: newPrice,
        color: newColor || null,
        from_name: supplier,
      });
  
      const addedPart = response.data;
      setParts((prev) => [...prev, addedPart]);
      createAndPrintQRCode(addedPart.part_id);
  
      // Dialógus bezárása és űrlap alaphelyzetbe állítása
      setIsDialogOpen(false);
      setNewPartType('');
      setNewPhoneType('');
      setNewPrice('');
      setNewColor('');
    } catch (error) {
      console.error('Error adding new part:', error);
    }
  };

  const createAndPrintQRCode = async (partId: string) => {
    const qrCodeData = JSON.stringify(partId);
    const base64QrCode = await generateQrCode(qrCodeData);
    openPrintWindow(base64QrCode);
  }

  const generateQrCode = async (data: string): Promise<string> => {
    const response = await fetch(`https://quickchart.io/qr?text=${encodeURIComponent(data)}&size=250`);
    const qrCodeBlob = await response.blob();
  
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string); // Explicit típus átalakítás string-re
      };
      reader.onerror = reject;
      reader.readAsDataURL(qrCodeBlob);
    });
  };
  
  const openPrintWindow = (base64QrCode: string) => {
    const printWindow = window.open('', '_blank');
    printWindow!.document.write(`
      <html>
        <head>
          <title>QR Code</title>
          <style>
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
              img {
                width: 19mm;
                height: 19mm;
              }
              @page {
                size: 19mm 19mm;
                margin: 0;
              }
            }
          </style>
        </head>
        <body>
          <img src="${base64QrCode}" alt="QR Code" />
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              };
            };
          </script>
        </body>
      </html>
    `);
    printWindow!.document.close();
  };

  // Toggle dialog
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const getPartTypeIcon = (partTypeName : string) => {
    const lowerCaseName = partTypeName.toLowerCase(); // Kisbetűssé alakítjuk a könnyebb egyezéshez
  
    if (lowerCaseName.includes('kijelző')) {
      return <ScreenRotation />;
    } else if (lowerCaseName.includes('akkumulátor')) {
      return <BatteryChargingFull />;
    } else if (lowerCaseName.includes('hátlap')) {
      return <PhoneIphone />;
    } else if (lowerCaseName.includes('kamera')) {
      return <CameraAlt />;
    } else if (lowerCaseName.includes('töltő')) {
      return <Power />;
    } else {
      return <Build />; // Alapértelmezett ikon, ha nincs találat
    }
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  // Toggle selection for part types and phone types (for filtering)
  const handlePartTypeSelection = (id: number) => {
    setSelectedPartTypes((prev) =>
      prev.includes(id) ? prev.filter((typeId) => typeId !== id) : [...prev, id]
    );
  };
  const handleSupplierSelection = (id: number) => {
    setSelectedSuppliers((prev) =>
      prev.includes(id) ? prev.filter((typeId) => typeId !== id) : [...prev, id]
    );
  };
  const handlePhoneTypeSelection = (id: number) => {
    setSelectedPhoneTypes((prev) =>
      prev.includes(id) ? prev.filter((typeId) => typeId !== id) : [...prev, id]
    );
  };

// Szűrt alkatrész típusok létrehozása
const availablePartTypes = partTypes.filter((partType) =>
    parts.some((part) => part.part_type_id === partType.id  && part.is_sold === false)
  );
  
  // Szűrt telefon típusok létrehozása
  const availablePhoneTypes = phoneTypes.filter((phoneType) =>
    parts.some((part) => part.phone_type_id === phoneType.id  && part.is_sold === false)
  );

  const availableFrom = suppliers.filter((supplier) =>
    parts.some((part) => part.from_name === supplier.name && part.is_sold === false)
  );
  // Filter parts based on selected filters
  const filteredParts = parts.filter(
    (part) =>
      !part.is_sold &&
      (selectedPartTypes.length === 0 || selectedPartTypes.includes(part.part_type_id)) &&
      (selectedPhoneTypes.length === 0 || selectedPhoneTypes.includes(part.phone_type_id)) &&
      (selectedSuppliers.length === 0 || selectedSuppliers.includes((suppliers.find(supplier => supplier.name === part.from_name)?.id ?? 1)))
  );

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom align="center">
          Raktárkészlet
        </Typography>

        {/* Add new part button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button variant="contained" color="primary" onClick={handleDialogOpen}>
            <AddIcon /> Új Alkatrész
          </Button>
        </Box>

        {/* Filters - part types and phone types */}
        <Box sx={{ mb: 3 }}>
  <Typography variant="body1">Alkatrész szűrők</Typography>
  {availablePartTypes.map((partType) => (
    <Button
      key={partType.id}
      variant={selectedPartTypes.includes(partType.id) ? 'contained' : 'outlined'}
      onClick={() => handlePartTypeSelection(partType.id)}
      sx={{ mr: 1, mb: 1 }}
    >
      {partType.name}
      <Checkbox checked={selectedPartTypes.includes(partType.id)} />
    </Button>
  ))}

  <Typography variant="body1" sx={{ mt: 2 }}>Telefon típus szűrők</Typography>
  {availablePhoneTypes.map((phoneType) => (
    <Button
      key={phoneType.id}
      variant={selectedPhoneTypes.includes(phoneType.id) ? 'contained' : 'outlined'}
      onClick={() => handlePhoneTypeSelection(phoneType.id)}
      sx={{ mr: 1, mb: 1 }}
    >
      {phoneType.name}
      <Checkbox checked={selectedPhoneTypes.includes(phoneType.id)} />
    </Button>
  ))}
  <Typography variant="body1" sx={{ mt: 2 }}>Beszerzési hely szűrők</Typography>
  {availableFrom.map((supplier) => (
    <Button
      key={supplier.id}
      variant={selectedSuppliers.includes(supplier.id) ? 'contained' : 'outlined'}
      onClick={() => handleSupplierSelection(supplier.id)}
      sx={{ mr: 1, mb: 1 }}
    >
      {supplier.name}
      <Checkbox checked={selectedSuppliers.includes(supplier.id)} />
    </Button>
  ))}
</Box>

        <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} size="small" aria-label="parts table">
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Dátum</TableCell>
        <TableCell>Beszerzési hely</TableCell>
        <TableCell>Alkatrész Típus</TableCell>
        <TableCell>Bruttó beszer. ár</TableCell>
        {userManager.checkIsSuperUser() && (<TableCell>Nettó beszer. ár</TableCell>)}
        <TableCell>Szín</TableCell>
        <TableCell>Telefon Típus</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredParts.length > 0 ? (
        filteredParts.map((part) => (
          <TableRow key={part.part_id} sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <TableCell>{getPartTypeIcon(part.part_type_name)}</TableCell>
            <TableCell>{part.purchase_date}</TableCell>
            <TableCell>{part.from_name}</TableCell>
            <TableCell>{part.part_type_name}</TableCell>
            <TableCell>{parseInt(part.price).toLocaleString()} Ft</TableCell>
            {userManager.checkIsSuperUser() && (<TableCell>{(parseInt(part.price)/1.27).toLocaleString()} Ft</TableCell>)}
            <TableCell>{part.color}</TableCell>
            <TableCell>{part.phone_type_name}</TableCell>
            <TableCell>
              <IconButton aria-label="qr code" onClick={() => createAndPrintQRCode(part.part_id.toString())}>
      <PrintTwoTone />
    </IconButton></TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={5}>
            <Typography variant="body1">Nincsenek megjeleníthető alkatrészek.</Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>
      </Paper>

      {/* Dialog for adding a new part */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Új Alkatrész Hozzáadása</DialogTitle>
        <DialogContent>
        <Autocomplete
      freeSolo
      options={phoneTypes.map((phoneType) => phoneType.name)}
      value={phoneTypes.find((type) => type.id === newPhoneType)?.name ?? ''}
      onChange={(event, newValue) => {
        setNewPhoneType(phoneTypes.find((type) => type.name === newValue)?.id ?? '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Telefon típus"
          fullWidth
          required
        />
      )}
    />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Alkatrész Típus</InputLabel>
            <Select
              value={newPartType}
              onChange={(e) => setNewPartType(e.target.value as number)}
            >
              {partTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Beszerzési Ár input */}
          <TextField
            label="Beszerzési Ár"
            type="number"
            fullWidth
            value={newPrice}
            onChange={(e) => setNewPrice(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
{/* Beszerzési Ár input */}
<Autocomplete
      freeSolo
      options={suppliers.map((supplier) => supplier.name)}
      value={supplier}
      onChange={(event, newValue) => {
        setSupplier(newValue ?? '');
      }}
      onInputChange={(event, newValue) => {
        setSupplier(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Beszerzési hely"
          fullWidth
          required
        />
      )}
    />
          {/* Szín input (optional) */}
          <TextField
            label="Szín (opcionális)"
            type="text"
            fullWidth
            value={newColor}
            onChange={(e) => setNewColor(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Mégse
          </Button>
          <Button onClick={handleAddPart} color="primary" disabled={!newPrice}>
            Hozzáadás
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PartsList;

