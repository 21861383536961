import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';



import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { styled } from '@mui/system';

import { NetworkManager } from "../services/NetworkManager";
import { Total } from '../models/WorkSheetModel';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Booking {
  id: string;
  appointment_date: string;
  user_name: string;
  model: string;
  service_types: string;
}

const dummyLeaveEmployees: string[] = [];

const dummySocialFollowers = {
  instagram: 0,
  youtube: 0,
  twitter: 0,
};

const monthlySalesExpenses = {
  sales: [],
  expenses: [],
  months: [],
};

// Styled components for modern design
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '20px',
  borderRadius: '15px',
  backgroundColor: '#f5f5f5',
  boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '10px',
}));

const Dashboard: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [currentDate, setCurrentDate] = useState('');
  const networkManager = useMemo(() => new NetworkManager(), []);
  const [financials, setFinancials] = useState<[Total, Total]>([{ cash: 0, card: 0 }, { cash: 0, card: 0 }]);
  
  useEffect(() => {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    setCurrentDate(today.toLocaleDateString('hu-HU', options));

    fetch('https://szerviz.slimstore.hu/api/bookings')
      .then(response => response.json())
      .then((data: Booking[]) => {
        const today = new Date().toISOString().split('T')[0];
        const todayBookings = data.filter((booking: Booking) =>
          booking.appointment_date && booking.appointment_date.startsWith(today)
        );
        setBookings(todayBookings);
      })
      .catch(error => {
        console.error('Error fetching bookings:', error);
      });
  }, []);

  // Chart Data Setup
  const data = {
    labels: monthlySalesExpenses.months,
    datasets: [
      {
        label: 'Bevétel',
        data: monthlySalesExpenses.sales,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
      {
        label: 'Kiadás',
        data: monthlySalesExpenses.expenses,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
    ],
  };
  const getWorksheets = useCallback(async () => {
    const today = new Date();
    const response = await networkManager.get('/api/worksheets/?date=' + today.toISOString().split('T')[0]);
    const worksheets = response.data.worksheets;

    const totalIncomes = worksheets.reduce(
      (totals: { cash: number; card: number }, worksheet: any) => {
        if (worksheet.payment_method === "card") {
          totals.card += worksheet.total_incomes;
        } else if (worksheet.payment_method === "cash") {
          totals.cash += worksheet.total_incomes;
        }
        return totals;
      },
      { cash: 0, card: 0 }
    );
      const expenseData = response.data.total_expenses
    setFinancials([totalIncomes, expenseData]);
  }, [networkManager]);

  useEffect(() => {
    getWorksheets();
  }, [getWorksheets]);
  
  
  return (
    <Grid container spacing={4}>
      {/* Napi kiadások és bevételek */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <SectionHeader variant="h6">Napi Bevétel (Ft)</SectionHeader>
          <Divider style={{ marginBottom: '20px' }} />
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Bankkártya</TableCell>
                  <TableCell>{financials[0].card.toLocaleString()} Ft</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Készpénz</TableCell>
                  <TableCell>{financials[0].cash.toLocaleString()} Ft</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>

      <Grid item xs={12} md={6}>
        <StyledPaper>
          <SectionHeader variant="h6">Napi Kiadás (Ft)</SectionHeader>
          <Divider style={{ marginBottom: '20px' }} />
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Bankkártya</TableCell>
                  <TableCell>{financials[1].card.toLocaleString()} Ft</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Készpénz</TableCell>
                  <TableCell>{financials[1].cash.toLocaleString()} Ft</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>

      {/* Szabadságon lévő alkalmazottak */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <SectionHeader variant="h6">Ma Szabadságon Van</SectionHeader>
          <Divider style={{ marginBottom: '20px' }} />
          {dummyLeaveEmployees.length > 0 ? (
            <ul>
              {dummyLeaveEmployees.map((employee, index) => (
                <li key={index}>{employee}</li>
              ))}
            </ul>
          ) : (
            <Typography>Nincs senki szabadságon ma.</Typography>
          )}
        </StyledPaper>
      </Grid>

      {/* Mai Dátum */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <SectionHeader variant="h6">Mai Dátum</SectionHeader>
          <Divider style={{ marginBottom: '20px' }} />
          <Typography>{currentDate}</Typography>
        </StyledPaper>
      </Grid>

      {/* Social Media Followers */}
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <SectionHeader variant="h6">Social Média Követők</SectionHeader>
          <Divider style={{ marginBottom: '20px' }} />
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Instagram</TableCell>
                  <TableCell>{dummySocialFollowers.instagram}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>YouTube</TableCell>
                  <TableCell>{dummySocialFollowers.youtube}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Facebook</TableCell>
                  <TableCell>{dummySocialFollowers.twitter}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
      <StyledPaper>
        <SectionHeader variant="h6">Mai időpont foglalások</SectionHeader>
        <Divider style={{ marginBottom: '20px' }} />
        { bookings.length > 0 ? (
          bookings.map((booking: Booking, index: number) => (
            <Typography key={index}>
              {new Date(booking.appointment_date).toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit' })} - {booking.user_name} - {booking.model} {booking.service_types.split(':')[1]}
            </Typography>
          ))
        ) : (
          <Typography>Ma nincs foglalás.</Typography>
        )}
      </StyledPaper>
    </Grid>
      {/* Havi bevétel és kiadás diagram */}
      
      <Grid item xs={12}>
        <StyledPaper>
          <SectionHeader variant="h6">Havi Bevétel és Kiadás</SectionHeader>
          <Divider style={{ marginBottom: '20px' }} />
          <div style={{ width: '100%', height: '400px' }}> {/* Set width to 100% */}
      <Line data={data} options={{ responsive: true, maintainAspectRatio: false }} />
    </div>
        </StyledPaper>
      </Grid>

      
    </Grid>
  );
};

export default Dashboard;